import { render, staticRenderFns } from "./financial.vue?vue&type=template&id=505fa1b6&scoped=true"
import script from "./financial.vue?vue&type=script&lang=js"
export * from "./financial.vue?vue&type=script&lang=js"
import style0 from "./financial.vue?vue&type=style&index=0&id=505fa1b6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505fa1b6",
  null
  
)

export default component.exports