<template>
	<div>
		<a-descriptions bordered class="line-top-descriptions" :title="$t('table.applyInfo.applicationLabel.creditFile')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.creditFile')">
				<a-checkbox-group v-model="checkedReport" :options="['Metropol', 'Transunion']" />
				<a-button type="primary" @click="getReport">
					{{ $t('page.GetReport') }}
				</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-tabs :defaultActiveKey="1" type="card" v-if="creditReport.idNumber" style="margin-top: 10px">
			<a-tab-pane :key="1" tab="Metropol">
				<a-descriptions class="line-bottom-descriptions" bordered size="small">
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.totalOutstandingBalance')">
						<p>{{ creditReport.totalOutstandingBalance }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.accountDefaultHistCount')">
						<p>{{ creditReport.accountDefaultHistCount }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.accountDefaultHistRate')">
						<p>{{ creditReport.accountDefaultHistRate }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.closedAccountWithoutDefaultHistCount')">
						<p>{{ creditReport.closedAccountWithoutDefaultHistCount }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.nonperformingAccountCount')">
						<p>{{ creditReport.nonperformingAccountCount }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.overdueAccountCount')">
						<p>{{ creditReport.overdueAccountCount }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.balanceOverdueTotal')">
						<p>{{ creditReport.balanceOverdueTotal }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.creditScore')">
						<p>{{ creditReport.creditScore }}</p>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.reportCloums.delinquencyCode')">
						<p>{{ creditReport.delinquencyCode }}</p>
					</a-descriptions-item>
				</a-descriptions>
			</a-tab-pane>
		</a-tabs>
		<a-tabs :defaultActiveKey="defaultActiveKey" type="card" :animated="true" style="margin-top: 10px">
			<a-tab-pane class="preview-box" v-if="creditMetropolFile">
				<div slot="tab">
					<span>Metropol</span>
					<a-button type="primary" style="margin-left: 20px" @click="downloadReport(creditMetropolFile, personalInfo.basicIdentityCard)">
						{{ $t('page.download') }}
					</a-button>
				</div>
				<iframe :src="creditMetropolFile" frameborder="0" class="iframe-box"> </iframe>
			</a-tab-pane>
			<a-tab-pane :key="2" class="preview-box" v-if="creditTransunionFile">
				<div slot="tab">
					<span>Transunion</span>
					<a-button type="primary" style="margin-left: 20px" @click="downloadReport(creditTransunionFile, personalInfo.basicIdentityCard)">
						{{ $t('page.download') }}
					</a-button>
				</div>
				<iframe :src="creditTransunionFile" frameborder="0" class="iframe-box"> </iframe>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
import { apiGetReport } from '@/api/loan'
import { _axios } from '@/plugins/axios'
export default {
	props: ['creditReport', 'personalInfo', 'id'],
	data() {
		return {
			defaultActiveKey: 1,
			creditMetropolFile: '',
			creditTransunionFile: '',
			checkedReport: ['Metropol']
		}
	},
	mounted() {
		if (this.creditReport.idNumber) {
			this.apiReport('Metropol')
		}
	},
	methods: {
		getReport() {
			this.$store.commit('pageState/setUploadState', true)
			if (this.checkedReport.length === 0) {
				this.$store.commit('pageState/setUploadState', false)
				this.$message.error('Select Empty')
				return false
			}
			this.checkedReport.includes('Metropol') ? this.apiReport('Metropol') : ''
			this.checkedReport.includes('Transunion') ? this.apiReport('Transunion') : ''
		},
		// 获取征信报告
		apiReport(type) {
			if (this.personalInfo.basicIdentityCard) {
				switch (type) {
					case 'Metropol':
						apiGetReport(this.personalInfo.basicIdentityCard)
							.then((res) => {
								this.$emit('setCreditReport', {
									idNumber: res.idNumber,
									totalOutstandingBalance: res.totalOutstandingBalance,
									accountDefaultHistCount: res.accountDefaultHistCount,
									accountDefaultHistRate: res.accountDefaultHistRate,
									closedAccountWithoutDefaultHistCount: res.closedAccountWithoutDefaultHistCount,
									nonperformingAccountCount: res.nonperformingAccountCount,
									overdueAccountCount: res.overdueAccountCount,
									balanceOverdueTotal: res.balanceOverdueTotal,
									creditScore: res.creditScore,
									delinquencyCode: res.delinquencyCode,
									loanId: this.id || null
								})
								_axios
									.get(`/car/loan/apply/data/obtain/reportFile?nationalId=${this.personalInfo.basicIdentityCard}`, { responseType: 'arraybuffer' })
									.then((res) => {
										this.creditMetropolFile =
											res.byteLength > 0 ? (window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
									})
							})
							.finally(() => {
								this.$store.commit('pageState/setUploadState', false)
							})
						break
					case 'Transunion':
						_axios
							.get(
								`/car/loan/apply/data/obtain/transunion/reportFile?nationalId=${this.personalInfo.basicIdentityCard}&firstName=${this.personalInfo.basicFirstName}&middleName=${this.personalInfo.basicMiddleName}&lastName=${this.personalInfo.basicLastName}`,
								{ responseType: 'arraybuffer' }
							)
							.then((res) => {
								this.creditTransunionFile =
									res.byteLength > 0 ? (window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
								this.defaultActiveKey = !this.creditMetropolFile && this.creditTransunionFile ? 2 : 1
							})
							.finally(() => {
								this.$store.commit('pageState/setUploadState', false)
							})
						break
					default:
						break
				}
			} else {
				this.$store.commit('pageState/setUploadState', false)
				this.$message.error('Please fill in the user’s ID number in the personal information')
				return false
			}
		},
		downloadReport(url, name) {
			const link = document.createElement('a')
			link.download = 'CRB Report' + '-' + name
			link.href = url
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}
}
</script>
<style scoped lang="less">
.line_span {
	display: inline-block;
	vertical-align: top;
	width: 200px;
	text-align: right;
	padding-right: 10px;
}

.ant-input,
.ant-select {
	width: 200px;
}
.input_table {
	width: 100%;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	width: 200px;
	vertical-align: top;
	display: inline-block;
	margin-right: 20px;
}
img {
	display: inline-block;
	width: 100px;
	height: 100px;
	margin-right: 20px;
	cursor: pointer;
}
.report {
	width: 100%;
	p {
		margin: 10px 0;
	}
}
</style>
