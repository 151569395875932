<template>
	<a-descriptions bordered :title="$t('table.applyInfo.loanApplyLabel.creatingMes')" size="small">
		<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.city')">
			<a-select v-model="creatingInfo.cityCode" @change="handleChangeCity">
				<a-select-option :value="item.id" v-for="item in cityList" :key="item.id">{{ isLang ? item.id : item.name }}</a-select-option>
			</a-select>
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.branchOffice')">
			<a-select v-model="creatingInfo.storeId" @change="handleChangeStore">
				<a-select-option :value="item.id" v-for="item in branchList" :key="item.id">{{ item.storeName }}</a-select-option>
			</a-select>
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.salesAgent')">
			<a-select v-model="creatingInfo.salesmanId" @change="handleChangeSalesman">
				<a-select-option :value="item.salesmanId" v-for="item in salesmanList" :key="item.salesmanId">{{ item.salesmanName }}</a-select-option>
			</a-select>
		</a-descriptions-item>
	</a-descriptions>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiCityList, apiBranchList } from '@/api/branch'
export default {
	props: ['creatingInfo'],
	data() {
		return {
			cityList: [],
			branchList: [],
			branchAllList: [],
			salesmanList: []
		}
	},
	computed: {
		...mapGetters(['setting']),
		isLang() {
			return this.setting.curLanguage.locale === 'en'
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			Promise.all([apiCityList(), apiBranchList()]).then((res) => {
				this.cityList = res[0]
				this.branchAllList = res[1]
				if (this.creatingInfo.cityCode) {
					this.branchAllList.map((branchItem) => {
						if (branchItem.cityCode === this.creatingInfo.cityCode) {
							this.branchList.push(branchItem)
						}
					})
				}
				if (this.creatingInfo.storeId) {
					this.branchList.map((item) => {
						if (this.creatingInfo.storeId === item.id) {
							this.salesmanList = item.salesmen
						}
					})
				}
			})
		},
		handleChangeCity(val) {
			this.branchList = []
			this.creatingInfo.storeName = ''
			this.creatingInfo.storeId = ''
			this.creatingInfo.salesmanName = ''
			this.creatingInfo.salesmanId = ''
			this.cityList.map((item) => {
				if (val === item.id) {
					this.creatingInfo.cityName = item.name
				}
			})
			this.branchAllList.map((branchItem) => {
				if (branchItem.cityCode === val) {
					this.branchList.push(branchItem)
				}
			})
		},
		handleChangeSalesman(val) {
			console.log(val)
			this.salesmanList.map((item) => {
				if (val === item.salesmanId) {
					this.creatingInfo.salesmanName = item.salesmanName
				}
			})
		},
		handleChangeStore(val) {
			this.creatingInfo.salesmanName = ''
			this.creatingInfo.salesmanId = ''
			this.branchList.map((item) => {
				if (val === item.id) {
					this.creatingInfo.storeName = item.storeName
					this.salesmanList = [...item.salesmen]
				}
			})
		}
	}
}
</script>
<style scoped lang="less">
.ant-row.ant-form-item {
	margin-bottom: 10px;
}
.ant-input-number,
.ant-select {
	width: 200px;
}
</style>
