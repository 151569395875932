<template>
	<div>
		<a-descriptions bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.loanExcel')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="loanApplyInfo.applyFile" :folderPath="loanApplyInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.loanMoney')">
				<a-input v-model="loanApplyInfo.applyAmount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.loanTerm')">
				<a-input v-model="loanApplyInfo.installmentsNumbers" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.relatives')" size="small">
			<a-descriptions-item :span="3">
				<a-table
					style="font-size: 12px"
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="loanApplyInfo.relatives"
					:pagination="false"
					bordered
				>
					<span slot="action" slot-scope="text, obj, index">
						<span>
							<a @click="EditRelative(text, obj, index)">{{ $t('page.edit') }}</a
							><a-divider type="vertical" />
						</span>
						<span>
							<a @click="DelRelative(text, obj, index)">{{ $t('page.delete') }}</a>
						</span>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<a-button type="primary" @click="AddRelative">{{ $t('page.add') }}</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.applicationLabel.social')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.facebookAccount')" :span="3">
				<a-input v-model="loanApplyInfo.socialAccounts[0].accountId" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.facebookPic')">
				<UploadPreviewFile
					:uploadFilePath.sync="loanApplyInfo.socialAccounts[0].accountScreenshot"
					:folderPath="loanApplyInfo.loanId"
					:isImgFile="true"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.applicationLabel.isGuarantee')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.isGuarantee')">
				<a-radio-group v-model="loanApplyInfo.hasGuarantor">
					<a-radio :value="0">
						{{ $t('page.no') }}
					</a-radio>
					<a-radio :value="1">
						{{ $t('page.yes') }}
					</a-radio>
				</a-radio-group>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-middle-descriptions" v-if="loanApplyInfo.hasGuarantor == 1" bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.gurForm')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="loanApplyInfo.guarantor.guarantorForm" :folderPath="loanApplyInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.firstName')">
				<a-input v-model="loanApplyInfo.guarantor.firstName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.middleName')">
				<a-input v-model="loanApplyInfo.guarantor.middleName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.lastName')">
				<a-input v-model="loanApplyInfo.guarantor.lastName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.sex')">
				<a-radio-group v-model="loanApplyInfo.guarantor.sex">
					<a-radio :value="0">
						{{ $t('table.applyInfo.personalLabel.sexMap.0') }}
					</a-radio>
					<a-radio :value="1">
						{{ $t('table.applyInfo.personalLabel.sexMap.1') }}
					</a-radio>
				</a-radio-group>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.mobile')">
				<a-input :maxLength="9" class="before-input" addon-before="254" v-model="loanApplyInfo.guarantor.mobile" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.idNumber')">
				<a-input v-model="loanApplyInfo.guarantor.identityCard" :maxLength="8" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.identityPic')" :span="3">
				<UploadPreviewFile
					:uploadFilePath.sync="loanApplyInfo.guarantor.identityCardFrontPic"
					:folderPath="loanApplyInfo.loanId"
					:isImgFile="true"
				></UploadPreviewFile>
				<UploadPreviewFile
					:uploadFilePath.sync="loanApplyInfo.guarantor.identityCardBackPic"
					:folderPath="loanApplyInfo.loanId"
					:isImgFile="true"
				></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item label="Photo" :span="3">
				<UploadPreviewFile
					:uploadFilePath.sync="loanApplyInfo.guarantor.photo"
					:folderPath="loanApplyInfo.loanId"
					:isImgFile="true"
				></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item label="PIN" :span="3">
				<a-input v-model="loanApplyInfo.guarantor.pin.pinNum" />
				<a-button type="primary" style="margin-left: 20px" @click="pinChecker">PIN Checker </a-button>
				<a-button @click="pinFileBool = !pinFileBool" style="margin-left: 10px">
					{{ $t('table.applyInfo.personalLabel.fillManual') }}
				</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" bordered v-show="pinFileBool && loanApplyInfo.hasGuarantor == 1">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxpayer')">
				<a-input v-model="loanApplyInfo.guarantor.pin.taxpayerName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.pinStatus')">
				<a-input v-model="loanApplyInfo.guarantor.pin.status" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxStatus')">
				<a-input v-model="loanApplyInfo.guarantor.pin.itaxStatus" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxInstitution')">
				<a-input v-model="loanApplyInfo.guarantor.pin.itaxObligation" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.effectiveTime')">
				<a-date-picker :valueFormat="dateFormat" showTime type="date" v-model="loanApplyInfo.guarantor.pin.effectiveTime" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
			<a-descriptions-item :span="3">
				<a-table
					:columns="supDocCloums"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="loanApplyInfo.supplementFiles"
					:pagination="false"
					bordered
				>
					<span slot="fileName" slot-scope="text, obj">
						<a-input v-model="obj.filename" class="input_table" />
					</span>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
							!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
						}}</a>
					</span>
					<span slot="fileRemark" slot-scope="text, obj">
						<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="DelSup(index)">{{ $t('page.delete') }}</a>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<UploadPreviewFile
					:uploadFilePath.sync="loanApplyInfo.supplementFiles"
					:folderPath="loanApplyInfo.loanId"
					:isPreviewFile="false"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<!-- 车辆归属 -->
		<a-modal v-drag-modal v-model="belongsModel.show" :title="belongsModel.title" :destroyOnClose="true" width="800px" @ok="handModalOk">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item label="PIN">
					<a-input v-model="belongsModel.pin" />
				</a-form-model-item>
				<a-form-model-item label="Name">
					<a-input v-model="belongsModel.name" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!-- 亲属 -->
		<a-modal v-drag-modal v-model="relativesModel.show" :title="relativesModel.title" :destroyOnClose="true" width="800px" @ok="handRelativeModalOk">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item :label="$t('table.applyInfo.applicationLabel.cloums.name')">
					<a-input v-model="relativesModel.name" />
				</a-form-model-item>
				<a-form-model-item :label="$t('table.applyInfo.applicationLabel.cloums.mobile')">
					<a-input :maxLength="9" class="before-input" addon-before="254" v-model="relativesModel.mobile" />
				</a-form-model-item>
				<a-form-model-item :label="$t('table.applyInfo.applicationLabel.cloums.address')">
					<a-input v-model="relativesModel.address" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!-- 地图 -->
		<a-modal v-drag-modal v-model="MapModal.show" title="Map" :destroyOnClose="true" width="800px" @ok="handMapModalOk">
			<a-form-model layout="inline">
				<a-form-model-item>
					<a-input v-model="MapModal.name" />
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="SearchMap" v-t.preserve="'operate.search2'"></a-button>
				</a-form-model-item>
			</a-form-model>
			<GmapMap :center="MapModal.center" :zoom="12" map-type-id="roadmap" style="width: 100%; height: 400px" ref="mapRef">
				<GmapMarker :position="MapModal.markpostion" :clickable="true" :draggable="true" @dragend="Dragend" ref="mark"> </GmapMarker>
			</GmapMap>
		</a-modal>
	</div>
</template>
<script>
import { gmapApi } from 'vue2-google-maps'
import { apiGetPIN } from '@/api/loan'
export default {
	props: ['loanApplyInfo'],
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			columns: [
				{
					// 姓名
					title: this.$t('table.applyInfo.applicationLabel.cloums.name'),
					dataIndex: 'name',
					align: 'center'
				},
				{
					// 电话
					title: this.$t('table.applyInfo.applicationLabel.cloums.mobile'),
					dataIndex: 'mobile',
					align: 'center'
				},
				{
					// 电话
					title: this.$t('table.applyInfo.applicationLabel.cloums.address'),
					dataIndex: 'address',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			],
			belongsModel: {
				title: '',
				show: false,
				pin: '',
				name: ''
			},
			relativesModel: {
				isAdd: true,
				title: '',
				show: false,
				idx: -1,
				id: '',
				name: '',
				mobile: '',
				address: ''
			},
			MapModal: {
				show: false,
				name: '',
				center: { lat: -1.294403, lng: 36.817582 },
				markpostion: { lat: -1.294403, lng: 36.817582 }
			},
			map: null,
			IsShowAddress: false,
			centerlatlng: {},
			logFileNestBool: false,
			pinFileBool: false
		}
	},
	computed: {
		supDocCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		},
		// 将谷歌地图api 赋值给 vue.google
		google: gmapApi
	},
	created() {
		this.$nextTick(() => {
			if (!this.$Util.isEmpty(this.loanApplyInfo.guarantor.pin.id)) {
				this.pinFileBool = true
			}
		})
	},
	methods: {
		AddBelongs() {
			this.belongsModel.title = this.$t('page.add')
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
			this.belongsModel.show = true
		},
		DelBelongs(idx) {
			this.loanApplyInfo.logbook.belongs.splice(idx, 1)
		},
		handModalOk() {
			const pinRege = /^[A-Za-z0-9]{11}$/
			if (!pinRege.test(this.belongsModel.pin)) {
				this.$message.error('pin error')
				return false
			} else if (!this.belongsModel.name) {
				this.$message.error('name error')
				return false
			}
			this.loanApplyInfo.logbook.belongs.push({
				pin: this.belongsModel.pin,
				name: this.belongsModel.name
			})
			this.belongsModel.show = false
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
		},
		AddRelative() {
			this.relativesModel.title = this.$t('page.add')
			this.relativesModel.isAdd = true
			this.relativesModel.id = ''
			this.relativesModel.name = ''
			this.relativesModel.mobile = ''
			this.relativesModel.address = ''
			this.relativesModel.idx = -1
			this.relativesModel.show = true
		},
		EditRelative(text, obj, index) {
			this.relativesModel.title = this.$t('page.edit')
			this.relativesModel.isAdd = false
			this.relativesModel.id = obj.id
			this.relativesModel.name = obj.name
			this.relativesModel.mobile = obj.mobile
			this.relativesModel.address = obj.address
			this.relativesModel.idx = index
			this.relativesModel.show = true
		},
		DelRelative(text, obj, index) {
			this.loanApplyInfo.relatives.splice(index, 1)
		},
		handRelativeModalOk() {
			// 校验手机号
			this.relativesModel.mobile = this.relativesModel.mobile.split(' ').join('')
			if (this.relativesModel.mobile.indexOf('254') == 0) {
				this.relativesModel.mobile = this.relativesModel.mobile.slice(3)
			} else if (this.relativesModel.mobile.indexOf('+254') == 0) {
				this.relativesModel.mobile = this.relativesModel.mobile.slice(4)
			}
			let reg = /^\d{9}$|^0\d{9}$/
			if (!reg.test(this.relativesModel.mobile)) {
				this.$message.error('mobile error')
				return false
			}
			if (!this.relativesModel.name) {
				this.$message.error('name error')
				return false
			}
			if (!this.relativesModel.address) {
				this.$message.error('address error')
				return false
			}

			if (this.relativesModel.isAdd) {
				this.loanApplyInfo.relatives.push({
					id: this.relativesModel.id,
					name: this.relativesModel.name,
					mobile: this.relativesModel.mobile,
					address: this.relativesModel.address
				})
			} else {
				this.loanApplyInfo.relatives[this.relativesModel.idx] = {
					id: this.relativesModel.id,
					name: this.relativesModel.name,
					mobile: this.relativesModel.mobile,
					address: this.relativesModel.address
				}
			}
			this.relativesModel.show = false
		},
		pinChecker() {
			if (this.loanApplyInfo.guarantor.pin.pinNum !== '') {
				this.$store.commit('pageState/setUploadState', true)
				apiGetPIN({ pin: this.loanApplyInfo.guarantor.pin.pinNum })
					.then((res) => {
						if (res.effectiveTime) {
							res.effectiveTime = res.effectiveTime.split('/').reverse().join('-')
						}
						this.loanApplyInfo.guarantor.pin = Object.assign(this.loanApplyInfo.guarantor.pin, res)
						this.pinFileBool = true
					})
					.catch(() => {
						this.pinFileBool = false
					})
					.finally(() => {
						this.$store.commit('pageState/setUploadState', false)
					})
			} else {
				this.$error({ content: 'Please fill in the PIN!' })
			}
		},
		// 显示地图
		ShowMap() {
			this.MapModal.show = true
		},
		// 查询地图位置
		SearchMap() {
			this.map = this.$refs.mapRef.$mapObject
			let request = {
				query: this.MapModal.name,
				fields: ['name', 'geometry']
			}
			let service = new this.google.maps.places.PlacesService(this.map)
			service.findPlaceFromQuery(request, (results, status) => {
				if (status === this.google.maps.places.PlacesServiceStatus.OK && results) {
					let loaction = {
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng()
					}
					this.MapModal.center = loaction
					this.MapModal.markpostion = loaction
				}
			})
		},
		// 提交地图信息
		handMapModalOk() {
			this.loanApplyInfo.address.location = this.MapModal.markpostion.lat + ',' + this.MapModal.markpostion.lng
			this.centerlatlng = this.MapModal.markpostion
			this.MapModal.show = false
			this.IsShowAddress = true
		},
		// 定位移动后获取坐标
		Dragend(e) {
			this.MapModal.markpostion = {
				lat: Number(e.latLng.lat().toFixed(6)),
				lng: Number(e.latLng.lng().toFixed(6))
			}
		},
		// 显示当前坐标位置
		ShowAddress() {
			this.MapModal.center = this.centerlatlng
			this.MapModal.markpostion = this.centerlatlng
			this.MapModal.show = true
		},
		// 补充文件删除
		DelSup(idx) {
			this.loanApplyInfo.supplementFiles.splice(idx, 1)
		}
	}
}
</script>
<style scoped lang="less">
.line_span {
	display: inline-block;
	vertical-align: top;
	width: 200px;
	text-align: right;
	padding-right: 10px;
}

.ant-input,
.ant-select {
	width: 200px;
}
.input_table {
	width: 100%;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	// width: 200px;
	vertical-align: top;
	display: inline-block;
	margin-right: 20px;
}
.report {
	width: 100%;
	p {
		margin: 10px 0;
	}
}
</style>
