<template>
	<div>
		<a-descriptions bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
				<a-radio-group v-model="loanInfoForm.loanType" @change="setLoanType">
					<span v-for="loanType in $loanMap.loanType" :key="loanType.value">
						<a-radio v-if="loanType.value !== undefined" :value="loanType.value">
							{{ $t(`table.applyInfo.financialLabel.loanMap.${loanType.value}`) }}
						</a-radio>
					</span>
				</a-radio-group>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered size="small" class="loan-info-form">
			<template slot="title">
				<span class="row-start-center">
					<span>{{ $t('table.applyInfo.tabsMenu.loanApply') }}</span>
					<span v-if="beingRevised">
						<a-button @click="saveApplyConfirmLoanInfo" type="primary" style="margin-left: 20px">{{ $t('operate.save') }}</a-button>
						<a-button @click="setBeingRevised" type="primary" style="margin-left: 20px">{{ $t('operate.cancel') }}</a-button>
					</span>
					<edit-deduction-modal :upfrontReduction.sync="loanInfoForm.upfrontReduction" @onSubmitSuccess="tryCalculate">
						<a-button slot="reference" style="margin-left: 10px" type="primary">Edit Deduction</a-button>
					</edit-deduction-modal>
				</span>
			</template>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.loanFees')">
				<a-input-number v-model="loanInfoForm.applyAmount" :min="0" :precision="0" @change="applyApplyMountTryCalculate"></a-input-number>
			</a-descriptions-item>
			<a-descriptions-item
				:label="
					loanInfoForm.loanType === 1 && descriptionsItem === 'maintenanceFee'
						? $t('table.applyInfo.loanApplyLabel.maintenanceFee2013')
						: $t(
								`table.applyInfo.loanApplyLabel.${
									$loanMap.loanType[loanInfoForm.loanType].aliasItem.includes(descriptionsItem) ? descriptionsItem + 'Alias' : descriptionsItem
								}`
						  )
				"
				v-for="descriptionsItem in $loanMap.loanType[loanInfoForm.loanType].descriptionsItem"
				:key="descriptionsItem"
			>
				<a-input-number
					v-if="is2013Rule(descriptionsItem)"
					v-model="loanInfoForm.maintenanceFeeInstallment"
					:min="0"
					:precision="0"
					@focus="inputFocus('maintenanceFeeInstallment')"
					@change="applyTryCalculate"
				></a-input-number>
				<a-input-number
					v-else
					v-model="loanInfoForm[descriptionsItem]"
					:min="0"
					:precision="0"
					@focus="inputFocus(descriptionsItem)"
					@change="applyTryCalculate"
				></a-input-number>
				<template v-if="descriptionsItem === 'gpsMonthAmount'">
					<div>Deduct the first month tracking</div>
					<a-radio-group v-model="loanInfoForm.firstMonthDeduce" @change="applyOtherTryCalculate">
						<a-radio :value="1"> Yes </a-radio>
						<a-radio :value="0"> No </a-radio>
					</a-radio-group>
				</template>
			</a-descriptions-item>
			<a-descriptions-item :label="reductions.name" v-for="reductions in loanInfoForm.reductions" :key="reductions.name">
				<a-input-number v-model="reductions.amount" :min="0" :precision="0" @change="applyOtherTryCalculate"></a-input-number>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.payable')">
				<a-input-number v-model="loanInfoForm.amountPayable" :precision="0" @change="applyNetMountTryCalculate"></a-input-number>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.MonthlyInterest')">
				<a-select v-model="loanInfoForm.interestRate" @change="applyOtherTryCalculate">
					<a-select-option :value="item" v-for="item in $loanMap.loanType[loanInfoForm.loanType].interestRateOptions" :key="item"
						>{{ (item * 100).toFixed(4) }}%</a-select-option
					>
				</a-select>
				<div style="margin-top: 5px">
					<a-input-number
						style="width: 100px"
						v-model="custRate"
						addonAfter="Add"
						:formatter="(value) => `${value ? value + '%' : ''}`"
						:parser="(value) => value.replace('%', '')"
					/>
					<a-button type="primary" style="margin-left: 10px" @click="addCustRate"> {{ $t('page.add') }} </a-button>
				</div>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.term')">
				<a-select v-model="loanInfoForm.installmentsNumbers" @change="applyInsNumTryCalculate">
					<a-select-option :value="item" v-for="item in $loanMap.loanType[loanInfoForm.loanType].installmentsOptions" :key="item">{{
						item
					}}</a-select-option>
				</a-select>
			</a-descriptions-item>
			<a-descriptions-item label="Upfront Fee - Deduction">
				<a-statistic :precision="2" :value="loanInfoForm.upfrontReduction?.toBeDeducedUpfront" />
			</a-descriptions-item>
			<a-descriptions-item label="Liquidation - Deduction">
				<a-statistic :precision="2" :value="loanInfoForm.upfrontReduction?.liquidation" />
			</a-descriptions-item>
			<a-descriptions-item label="Other Deduction">
				<a-statistic :precision="2" :value="loanInfoForm.upfrontReduction?.otherDeduce" />
			</a-descriptions-item>
			<a-descriptions-item label="Net Disbursement Amount">
				<a-statistic :precision="2" :value="loanInfoForm.netDisbursementAmount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.firstMonthRepayment')">
				<a-statistic :precision="2" :value="loanInfoForm.firstMonthRepay" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.lastRepayment')">
				<a-statistic :precision="2" :value="loanInfoForm.otherMonthRepay" />
			</a-descriptions-item>
			<!-- <a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.reductions')">
				<a-button type="primary" @click="addNewDeduction = true"> {{ $t('page.add') }} </a-button>
			</a-descriptions-item> -->
			<a-descriptions-item label="Disbursement Method">
				<a-radio-group v-model="loanInfoForm.disburseType">
					<a-radio value="choice"> Choice </a-radio>
					<a-radio value="dtb"> DTB </a-radio>
				</a-radio-group>
			</a-descriptions-item>
		</a-descriptions>
		<!-- <a-modal v-drag-modal title="Add New Deduction" v-model="addNewDeduction" @ok="saveNewDeduction">
			<a-descriptions bordered size="small">
				<a-descriptions-item v-for="(reductions, index) in newDeductionList" :key="index" :span="3">
					<template slot="label">
						<a-input v-model="reductions.name"> <a-icon slot="addonBefore" type="delete" @click="deleteDeduction(index)" /></a-input>
					</template>
					<a-input-number v-model="reductions.amount" :precision="0"> </a-input-number>
				</a-descriptions-item>
			</a-descriptions>
			<a-button type="primary" block @click="addDeduction" :disabled="newDeductionList.length > 9"> {{ $t('page.add') }} </a-button>
		</a-modal> -->
	</div>
</template>
<script>
import { apiApplyConfirmLoanInfo, apiApplyTryCalculate } from '@/api/loan'
import EditDeductionModal from '@/views/businessComponents/edit-deduction-modal.vue'

export default {
	components: {
		EditDeductionModal
	},
	data() {
		return {
			// addNewDeduction: false,
			disabledAdd: false,
			isReverse: false,
			loanInfoForm: {
				loanType: 0,
				applyAmount: null,
				disposePercent: 3,
				insurancePercent: 3,
				loanProcessingFee: null,
				exciseDuty: null,
				creditLifeInsuranceFee: null,
				lessLoanBalance: 0,
				crbAmount: 500,
				transferAmount: 2100,
				gpsInstallAmount: 2500,
				gpsMonthAmount: 2500,
				mortgageRegistrationAmount: 2500,
				firstMonthDeduce: 1,
				interestRate: '0.08',
				installmentsNumbers: 3,
				insurancePremium: 0, //保险费用
				engineParticulars: 0, //发动机费用
				incharge: 0, //维护费用
				amountPayable: 0,
				toBeDeducedUpfront: 0,
				liquidation: 0,
				otherDeduce: 0,
				upfrontReduction: {},
				netDisbursementAmount: 0,
				firstMonthRepay: 0,
				otherMonthRepay: 0,
				reductions: [],
				maintenanceFee: null, //Loan Maintenance fess
				maintenanceFeeInstallment: 500, //Loan Maintenance fess
				applicationFee: 3800, //Loan Application Fee
				kraEntryFee: 1700, //KRA Entry Fee
				disburseType: 'choice'
			},
			currentInput: null,
			newDeductionList: [],
			custRate: null,
			tryCalculateCarYear: '2014'
		}
	},
	props: ['loanInfo', 'loanCarInfos', 'beingRevised'],
	watch: {
		loanCarInfos: {
			handler: function (newValue) {
				if (newValue && newValue.length > 0) {
					const hasBefore2013 = newValue.some((i) => {
						const year = Number(i.logbook.year)
						return year > 0 && year <= 2013
					})
					if (hasBefore2013) {
						this.tryCalculateCarYear = '2013'
						if (this.loanInfoForm.loanType === 1 && this.loanInfoForm.maintenanceFee) this.loanInfoForm.maintenanceFee = null
						if (this.loanInfoForm.loanType === 1 && this.loanInfoForm.maintenanceFeeInstallment === 0)
							this.loanInfoForm.maintenanceFeeInstallment = 600
						if (this.loanInfoForm.loanType === 1) {
							this.loanInfoForm.loanProcessingFee = ''
							this.loanInfoForm.creditLifeInsuranceFee = ''
						}
					} else {
						this.tryCalculateCarYear = '2014'
						if (this.loanInfoForm.loanType === 1 && this.loanInfoForm.maintenanceFeeInstallment) this.loanInfoForm.maintenanceFeeInstallment = 0
						if (this.loanInfoForm.loanType === 1) {
							this.loanInfoForm.maintenanceFee = ''
						}
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.$nextTick(() => {
			this.loanInfoForm = this.loanInfo
			if (!this.loanInfo.upfrontReduction) {
				this.$set(this.loanInfoForm, 'upfrontReduction', {
					upfrontSwitch: 0, //upfront开关，1开，0关
					collectedUpfront: 0, //已收取的upfront费用
					toBeDeducedUpfront: 0, //待收取的upfront费用
					liquidationSwitch: 0, //上一笔贷款待还金额开关
					liquidation: 0, //上一笔贷款待还金额
					regNo: '', //上一笔贷款车牌号
					otherSwitch: 0, //其他费用开关
					otherDeduce: 0, //其他费用金额
					description: '' //其他费用描述
				})
			}
			this.newDeductionList = this.loanInfo.reductions
		})
	},
	methods: {
		setLoanType(val) {
			this.$emit('setDataChange', {
				key: 'financeInfo.loanType',
				value: val.target.value
			})
			this.loanInfoForm.interestRate = this.$loanMap.loanType[val.target.value].defaultsValue.interestRate
			this.loanInfoForm.installmentsNumbers = this.$loanMap.loanType[val.target.value].defaultsValue.installmentsNumbers
			this.loanInfoForm.applicationFee = this.$loanMap.loanType[val.target.value].defaultsValue.applicationFee
			this.loanInfoForm.maintenanceFeeInstallment = this.$loanMap.loanType[val.target.value].defaultsValue.maintenanceFeeInstallment
			if (this.loanInfoForm.loanType === 1 && this.tryCalculateCarYear === '2013') this.loanInfoForm.maintenanceFeeInstallment = 600
			if (this.loanInfoForm.loanType === 1 && this.tryCalculateCarYear === '2014') this.loanInfoForm.maintenanceFeeInstallment = 0
			this.applyOtherTryCalculate()
		},
		saveApplyConfirmLoanInfo() {
			this.loanInfoForm.disposePercent = this.loanInfoForm.disposePercent * 0.01
			this.loanInfoForm.insurancePercent = this.loanInfoForm.insurancePercent * 0.01
			apiApplyConfirmLoanInfo({
				...this.loanInfoForm
			}).then(() => {
				this.setBeingRevised()
			})
		},
		setBeingRevised() {
			this.$emit('setDataChange', {
				key: 'beingRevised',
				value: false
			})
		},
		// saveNewDeduction() {
		// 	this.loanInfoForm.reductions = [...this.newDeductionList]
		// 	this.applyOtherTryCalculate()
		// 	this.addNewDeduction = false
		// },
		// deleteDeduction(index) {
		// 	this.newDeductionList.splice(index, 1)
		// },
		addCustRate() {
			if (!this.$loanMap.loanType[this.loanInfoForm.loanType].interestRateOptions.includes(Number(this.custRate) / 100)) {
				this.$loanMap.loanType[this.loanInfoForm.loanType].interestRateOptions.push(Number(this.custRate) / 100)
			}
			this.loanInfoForm.interestRate = Number(this.custRate) / 100
			this.applyOtherTryCalculate()
		},
		// addDeduction() {
		// 	if (this.newDeductionList.length < 10) {
		// 		this.newDeductionList.push({
		// 			name: '',
		// 			amount: 0
		// 		})
		// 	}
		// },
		inputFocus(value) {
			this.currentInput = value
		},
		applyTryCalculate(value) {
			if (this.isReverse && this.loanInfoForm.loanType === 2) {
				this.loanInfoForm.applyAmount = null
			}
			if (this.currentInput === 'loanProcessingFee') {
				this.loanInfoForm.loanProcessingFee = value
			}
			if (this.currentInput === 'exciseDuty') {
				this.loanInfoForm.exciseDuty = value
			}
			if (this.currentInput === 'creditLifeInsuranceFee') {
				this.loanInfoForm.creditLifeInsuranceFee = value
			}
			if (this.currentInput === 'maintenanceFee') {
				this.loanInfoForm.maintenanceFee = value
			}
			this.tryCalculate()
		},
		applyOtherTryCalculate() {
			if (this.isReverse && this.loanInfoForm.loanType === 2) {
				this.loanInfoForm.applyAmount = null
			}
			this.tryCalculate()
		},
		applyInsNumTryCalculate(value) {
			// val.target.value
			if (this.loanInfoForm.loanType === 0) {
				if (this.loanInfoForm.installmentsNumbers > 0 && this.loanInfoForm.installmentsNumbers < 4) {
					this.loanInfoForm.interestRate = 0.08
				} else if (this.loanInfoForm.installmentsNumbers > 3 && this.loanInfoForm.installmentsNumbers < 7) {
					this.loanInfoForm.interestRate = 0.05
				} else if (this.loanInfoForm.installmentsNumbers > 6) {
					this.loanInfoForm.interestRate = 0.04
				}
			} else if (this.loanInfoForm.loanType === 1) {
				if (this.loanInfoForm.installmentsNumbers > 0 && this.loanInfoForm.installmentsNumbers < 4) {
					this.loanInfoForm.interestRate = 0.08
				} else if (this.loanInfoForm.installmentsNumbers > 3 && this.loanInfoForm.installmentsNumbers < 7) {
					this.loanInfoForm.interestRate = 0.06
				} else if (this.loanInfoForm.installmentsNumbers > 6 && this.loanInfoForm.installmentsNumbers < 12) {
					this.loanInfoForm.interestRate = 0.05
				} else {
					this.loanInfoForm.interestRate = 0.0295
				}
			}
			this.tryCalculate()
		},
		applyApplyMountTryCalculate(value) {
			this.isReverse = false
			this.loanInfoForm.applyAmount = value
			this.loanInfoForm.loanProcessingFee = null
			this.loanInfoForm.exciseDuty = null
			this.loanInfoForm.creditLifeInsuranceFee = null
			this.loanInfoForm.maintenanceFee = null
			this.tryCalculate()
		},
		applyNetMountTryCalculate(value) {
			this.isReverse = true
			this.loanInfoForm.applyAmount = null
			this.loanInfoForm.loanProcessingFee = null
			this.loanInfoForm.exciseDuty = null
			this.loanInfoForm.creditLifeInsuranceFee = null
			this.loanInfoForm.maintenanceFee = null
			this.loanInfoForm.amountPayable = value
			this.tryCalculate()
		},
		tryCalculate() {
			apiApplyTryCalculate({
				...this.loanInfoForm,
				carYear: this.tryCalculateCarYear
			}).then((res) => {
				this.loanInfoForm = res
				this.$emit('setDataChange', {
					key: 'loanInfo',
					value: res
				})
			})
		},
		is2013Rule(descriptionsItem) {
			return this.loanInfoForm.loanType === 1 && this.tryCalculateCarYear === '2013' && descriptionsItem === 'maintenanceFee'
		}
	}
}
</script>
<style scoped lang="less">
/deep/ .loan-info-form {
	.ant-input-number-handler-wrap {
		display: none !important;
	}
}

.ant-row.ant-form-item {
	margin-bottom: 10px;
}
.ant-input-number,
.ant-select {
	width: 200px;
}
</style>
