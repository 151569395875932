<template>
	<div>
		<a-descriptions
			class="table-descriptions"
			:title="financeInfo.loanType == 4 ? $t('table.applyInfo.financialLabel.flow') + $t('page.optional') : $t('table.applyInfo.financialLabel.flow')"
			size="small"
		>
			<a-descriptions-item :span="3">
				<a-table
					style="font-size: 12px"
					:columns="columns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="financeInfo.transcations"
					:pagination="false"
					bordered
				>
					<div slot="file" slot-scope="text, obj">
						<a @click="openUrl(obj)">{{ obj.file }}</a>
					</div>
					<span slot="action" slot-scope="text, obj, index">
						<a-button @click="delFile(index)">{{ $t('table.useraccount.file.cloums.del') }}</a-button>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<a-button type="primary" @click="ShowModal">
					<a-icon type="upload"></a-icon>
					{{ $t('table.applyInfo.personalLabel.uploadFile') }}
				</a-button>
				<!-- <a-button type="primary" @click="SendLoanFileCredit" style="margin-left: 20px" :disabled="financeInfo.transcations.length == 0">
					{{ $t('page.reviewFile') }}
				</a-button> -->
				<a-button type="primary" @click="CheckLoanDetail" style="margin-left: 20px" :disabled="financeInfo.transcations.length == 0">
					{{ $t('page.checkReview') }}
				</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
				<!-- <a-radio-group v-model="financeInfo.loanType" disabled>
					<a-radio v-for="loanType in $loanMap.loanType" :key="loanType.value" :value="loanType.value">
						{{ $t(`table.applyInfo.financialLabel.loanMap.${loanType.value}`) }}
					</a-radio>
				</a-radio-group> -->
				<p>{{ $t(`table.applyInfo.financialLabel.loanMap.${financeInfo.loanType}`) }}</p>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" v-show="financeInfo.loanType == 1" bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.money')" :span="3">
				<a-input-number v-model="financeInfo.carAmount" :precision="0" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.invoice') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="financeInfo.invoiceFile" :folderPath="financeInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.contract') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="financeInfo.contractFile" :folderPath="financeInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.proofofPayment') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="financeInfo.proofOfPayment" :folderPath="financeInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.sellerAgency')">
				<a-input v-model="financeInfo.sellerInstitution" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.contact') + $t('page.optional')">
				<a-input v-model="financeInfo.sellerName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.mobile') + $t('page.optional')">
				<a-input :maxLength="9" class="before-input" addon-before="254" v-model="financeInfo.sellerMobile" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.account')">
				<a-input v-model="financeInfo.sellerAccount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.purchaseTime') + $t('page.optional')">
				<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="financeInfo.purchaseTime" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
			<a-descriptions-item :span="3">
				<a-table
					:columns="supCloums"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="financeInfo.supplementFiles"
					:pagination="false"
					bordered
				>
					<span slot="fileName" slot-scope="text, obj">
						<a-input :disabled="index === 0" v-model="obj.filename" class="input_table" />
					</span>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
							!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
						}}</a>
					</span>
					<span slot="fileRemark" slot-scope="text, obj">
						<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="DelSup(index)">{{ $t('page.delete') }}</a>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<UploadPreviewFile
					:uploadFilePath.sync="financeInfo.supplementFiles"
					:folderPath="financeInfo.loanId"
					:isPreviewFile="false"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-modal
			v-drag-modal
			v-model="modal.show"
			:title="$t('table.useraccount.addfilemodal.title')"
			:destroyOnClose="true"
			width="600px"
			ok-text="ok"
			cancel-text="cancel"
			:confirm-loading="modal.loading"
			@ok="submitFile"
		>
			<a-form :form="modal" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
				<a-form-item :label="$t('table.useraccount.addfilemodal.bankName')">
					<a-select v-model="modal.bankname" style="width: 300px" @change="changeBankname">
						<a-select-option :value="item.codeValue" v-for="item in banklist" :key="item.id">
							{{ item.codeDescription }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item :label="$t('table.useraccount.addfilemodal.password')">
					<a-input v-model="modal.filePassword" />
				</a-form-item>
				<a-form-item :label="$t('table.useraccount.addfilemodal.file')">
					<a-button type="primary" class="file_btn">
						{{ $t('table.useraccount.addfilemodal.choosefile') }}
						<input type="file" ref="files_dom" class="input_file" @change="ChangeFile" />
					</a-button>
					<p>{{ modal.fileName }}</p>
				</a-form-item>
			</a-form>
		</a-modal>
		<!-- 流水弹窗 -->
		<a-modal v-drag-modal v-model="fullModal.show" title="Serial documents" :footer="false" width="90%" wrapClassName="full-modal">
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" :tab="$t('table.useraccount.result.tab1')">
					<information :details.sync="details" v-if="fullModal.show"></information>
				</a-tab-pane>
				<a-tab-pane key="2" :tab="$t('table.useraccount.result.tab3')">
					<flowDetails :reqParam="fileNameList" :isFileResult="true" v-if="fullModal.show"></flowDetails>
				</a-tab-pane>
				<a-tab-pane key="3" :tab="$t('table.useraccount.result.tab2')" force-render>
					<transactions :id="financeInfo.transcations" :isFileResult="true" v-if="fullModal.show"></transactions>
				</a-tab-pane>
			</a-tabs>
		</a-modal>
	</div>
</template>
<script>
import { _axios } from '@/plugins/axios'
import { apiGetCodeByName } from '@/api/code'
import { apiLoanDetail, apiGetFileStatus } from '@/api/loan'
import information from '@/views/review/components/information'
import flowDetails from '@/views/review/components/flowDetails'
import transactions from '@/views/review/components/transactions'
import moment from 'moment'
export default {
	props: ['financeInfo', 'personalInfo'],
	data() {
		return {
			dateFormatVal: 'YYYY-MM-DD HH:mm:ss',
			columns: [
				{
					// 流水机构
					title: this.$t('table.applyInfo.financialLabel.bankType'),
					dataIndex: 'institutionName',
					align: 'center'
				},
				{
					// 文件名称
					title: this.$t('table.applyInfo.financialLabel.fileName'),
					scopedSlots: { customRender: 'file' },
					// dataIndex: 'file',
					align: 'center'
				},
				{
					// 文件密码
					title: this.$t('table.applyInfo.financialLabel.filePassword'),
					dataIndex: 'filePassword',
					align: 'center'
				},
				{
					// 状态
					title: this.$t('table.loanInfo.caseInfo.status'),
					dataIndex: 'status',
					align: 'center',
					customRender: (v, o) => (v ? this.$t(`table.useraccount.file_status_map.${v}`) : '-')
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			],
			invoiceFileUrl: '',
			invoiceFileName: '',
			contractFileUrl: '',
			contractFileName: '',
			proFormaInvoiceUrl: '',
			proFormaInvoiceName: '',
			postDatedChequesUrl: '',
			postDatedChequesName: '',
			saleAgreementUrl: '',
			saleAgreementName: '',
			banklist: [],
			modal: {
				loading: false,
				show: false,
				filePassword: '',
				bankname: '',
				fileName: '',
				files: {}
			},
			fullModal: {
				show: false
			},
			details: null,
			fileSrc: ''
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		},
		fileNameList() {
			let fileNameList = []
			this.financeInfo.transcations.map((item) => {
				fileNameList.push(item.file)
			})
			return fileNameList
		}
	},
	methods: {
		openUrl(val) {
			_axios
				.get(`/car/file/download/${val.file}`, { responseType: 'arraybuffer' })
				.then((res) => {
					window.open((window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' })), '_blank')
				})
				.catch((err) => {
					console.log(err)
				})
		},
		moment: moment,
		// 添加文件
		submitFile() {
			if (this.modal.bankname == '') {
				this.$message.error(this.$t('table.userFrom.warnsTxt4'))
				return false
			}
			if (this.modal.bankname === 'MPESA' && !this.personalInfo.basicIdentityCard) {
				this.$message.error('Please fill in the user’s ID number in the personal information')
				return false
			}
			if (this.modal.fileName == '') {
				this.$message.error(this.$t('table.userFrom.warnsTxt3'))
				return false
			}
			const name = new Date().getTime() + '.' + this.$refs.files_dom.files[0].name
			const fromData = new FormData()
			this.$set(this.modal.files, name, this.$refs.files_dom.files[0])
			console.log(this.modal.files)
			for (let key in this.modal.files) {
				fromData.append('files', this.modal.files[key], key)
			}
			fromData.append(
				'passwordInfo',
				JSON.stringify({
					[name]: this.modal.filePassword
				})
			)
			fromData.append(
				'bankType',
				JSON.stringify({
					[name]: this.modal.bankname
				})
			)

			this.$store.commit('pageState/setUploadState', true)
			this.modal.loading = true
			_axios
				.post('/car/other/loanAddFile', fromData, {
					headers: { 'Content-Type': 'multipart/form-data' }
				})
				.then(() => {
					this.$message.success('success')
					this.financeInfo.transcations.unshift({
						id: '',
						file: name,
						filePassword: this.modal.filePassword,
						institution: this.modal.bankname,
						institutionName: this.banklist.find((item) => this.modal.bankname === item.codeValue).codeDescription,
						status: '0'
					})
				})
				.finally(() => {
					this.modal.loading = false
					this.modal.show = false
					this.modal.files = {}
					this.$store.commit('pageState/setUploadState', false)
				})
		},
		// 显示弹窗
		ShowModal() {
			this.modal.show = true
			this.modal.fileName = ''
			this.modal.filePassword = ''
		},
		changeBankname(val) {
			if (this.personalInfo.basicIdentityCard && val === 'MPESA') {
				this.modal.filePassword = this.personalInfo.basicIdentityCard
			} else {
				this.modal.filePassword = ''
			}
		},
		// 改变文件
		ChangeFile() {
			if (this.$refs.files_dom.files.length > 0) {
				this.modal.fileName = this.$refs.files_dom.files[0].name
			}
		},
		// 删除文件
		delFile(index) {
			this.financeInfo.transcations.splice(index, 1)
		},
		// 查看流水解析结果
		CheckLoanDetail() {
			let fileNames = []
			this.financeInfo.transcations.map((item) => {
				fileNames.push(item.file)
			})
			let fileNamesStr = ''
			fileNames.forEach((element) => {
				fileNamesStr += 'fileNames=' + element + '&'
			})
			apiGetFileStatus(fileNamesStr).then((res) => {
				if (res) {
					this.financeInfo.transcations.forEach((element) => {
						element.status = res[element.file]
					})
				}
			})
			let params = {
				fileNames: '',
				date: this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			}
			params.fileNames = fileNames.join(',')
			apiLoanDetail(params).then((res) => {
				this.details = res
				if (this.details) {
					this.fullModal.show = true
				}
			})
		},
		// 补充文件删除
		DelSup(idx) {
			this.financeInfo.supplementFiles.splice(idx, 1)
		}
	},
	created() {
		apiGetCodeByName({ name: 'BANK_NAME' }).then((res) => {
			this.banklist = res.values
			this.banklist.unshift({
				codeDescription: 'Mpesa',
				codeValue: 'MPESA'
			})
		})
	},
	components: {
		information,
		transactions,
		flowDetails
	}
}
</script>
<style scoped lang="less">
.line_span {
	display: inline-block;
	vertical-align: top;
	width: 200px;
	text-align: right;
	padding-right: 10px;
}
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	width: 200px;
	vertical-align: top;
	display: inline-block;
	margin-right: 20px;
}
.input_file {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	left: 0;
	top: 0;
}
.full-modal {
	.ant-modal {
		max-width: 100%;
		top: 0;
		padding-bottom: 0;
		margin: 0;
	}
	.ant-modal-content {
		display: flex;
		flex-direction: column;
		height: calc(100vh);
	}
	.ant-modal-body {
		flex: 1;
	}
}
</style>
