<template>
	<div v-if="personalInfo">
		<a-descriptions bordered :title="$t('table.applyInfo.personalLabel.basic')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.firstName')">
				<a-input v-model="personalInfo.basicFirstName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.middleName')">
				<a-input v-model="personalInfo.basicMiddleName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.lastName')">
				<a-input v-model="personalInfo.basicLastName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.sex')">
				<a-radio-group v-model="personalInfo.basicSex">
					<a-radio :value="0">
						{{ $t('table.applyInfo.personalLabel.sexMap.0') }}
					</a-radio>
					<a-radio :value="1">
						{{ $t('table.applyInfo.personalLabel.sexMap.1') }}
					</a-radio>
				</a-radio-group>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.mobile')">
				<div v-if="personalInfo.mobiles">
					<div v-for="(mobile, index) in personalInfo.mobiles" :key="index" style="margin-bottom: 5px">
						<a-input :maxLength="9" class="before-input" addon-before="254" v-model="personalInfo.mobiles[index].mobile">
							<a-icon slot="addonAfter" type="minus" v-if="personalInfo.mobiles.length > 1" @click="personalInfo.mobiles.splice(index, 1)" />
						</a-input>
					</div>
					<a-button
						type="primary"
						block
						icon="plus"
						v-if="personalInfo.mobiles.length < 5"
						@click="
							personalInfo.mobiles.push({
								mobile: ''
							})
						"
					/>
				</div>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.idNumber')">
				<a-input :maxLength="8" v-model="personalInfo.basicIdentityCard" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.dateOfBirth')">
				<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="personalInfo.birthday" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.Permission.User.email')" :span="2">
				<a-input :maxLength="50" v-model="personalInfo.email" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.customerPic')" :span="3">
				<UploadPreviewFile
					:uploadFilePath.sync="personalInfo.customerIdPic"
					:folderPath="personalInfo.loanId"
					:isImgFile="true"
					:uploadFile="'uploadCustomerPic'"
				>
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 1
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.identityPic')" :span="3">
				<UploadPreviewFile
					:uploadFilePath.sync="personalInfo.identityCardFront"
					:folderPath="personalInfo.loanId"
					:isImgFile="true"
					:uploadFile="'uploadFront'"
				></UploadPreviewFile>
				<UploadPreviewFile
					:uploadFilePath.sync="personalInfo.identityCardBack"
					:folderPath="personalInfo.loanId"
					:isImgFile="true"
					:uploadFile="'uploadBack'"
				></UploadPreviewFile>
				<UploadPreviewFile
					:uploadFilePath.sync="personalInfo.identityCardHold"
					:folderPath="personalInfo.loanId"
					:isImgFile="true"
					:uploadFile="'uploadHandheld'"
				>
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 2
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.personalLabel.pinfile')" size="small">
			<a-descriptions-item label="PIN" :span="3">
				<a-input v-model="personalInfo.pin.pinNum" />
				<a-button type="primary" style="margin-left: 20px" @click="pinChecker">PIN Checker </a-button>
				<a-button @click="pinFileBool = !pinFileBool" style="margin-left: 10px">
					{{ $t('table.applyInfo.personalLabel.fillManual') }}
				</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" v-show="pinFileBool" bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxpayer')">
				<a-input v-model="personalInfo.pin.taxpayerName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.pinStatus')">
				<a-input v-model="personalInfo.pin.status" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxStatus')">
				<a-input v-model="personalInfo.pin.itaxStatus" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxInstitution')">
				<a-input v-model="personalInfo.pin.itaxObligation" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.effectiveTime')">
				<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="personalInfo.pin.effectiveTime" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.personalLabel.source')" size="small">
			<a-descriptions-item :label="$t('table.CustomerManagement.salayBand')" :span="3">
				<a-select v-model="personalInfo.incomeSource.salaryBand" style="width: 300px">
					<a-select-option value="">Please select</a-select-option>
					<a-select-option :value="item.value" v-for="item in $enumMap.salayBandEnum" :key="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.caseInfo.employmentStatus')" :span="3">
				<a-select v-model="personalInfo.incomeSource.employmentStatus" style="width: 300px">
					<a-select-option value="">Please select</a-select-option>
					<a-select-option :value="item.value" v-for="item in $enumMap.employmentStatusEnum" :key="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-descriptions-item>
			<a-descriptions-item
				:label="$t('table.loanInfo.caseInfo.noOfYears')"
				:span="3"
				v-if="personalInfo.incomeSource.employmentStatus && personalInfo.incomeSource.employmentStatus !== 'UNEMPLOYMENT'"
			>
				<a-select v-model="personalInfo.incomeSource.noOfYears" style="width: 300px">
					<a-select-option value="">Please select No. of Years</a-select-option>
					<a-select-option :value="item.value" v-for="item in $enumMap.noOfYearsEnum" :key="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions
			class="line-bottom-descriptions"
			v-if="personalInfo.incomeSource.employmentStatus == 'PUBLIC SECTOR' || personalInfo.incomeSource.employmentStatus == 'PRIVATE SECTOR'"
			bordered
			size="small"
		>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.company') + $t('page.optional')">
				<a-input v-model="personalInfo.incomeSource.company" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.employer') + $t('page.optional')">
				<a-input v-model="personalInfo.incomeSource.employerName" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.employerMobile') + $t('page.optional')">
				<a-input :maxLength="9" class="before-input" addon-before="254" v-model="personalInfo.incomeSource.employerMobile" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.employerEmail') + $t('page.optional')">
				<a-input v-model="personalInfo.incomeSource.employerEmail" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.companyNo') + $t('page.optional')">
				<a-input v-model="personalInfo.incomeSource.companyAccount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.salaryReceivedNo') + $t('page.optional')">
				<a-input v-model="personalInfo.incomeSource.salaryAccount" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.remark') + $t('page.optional')" :span="3">
				<a-input type="textarea" :rows="4" :maxLength="100" v-model="personalInfo.incomeSource.remarks" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.documentation') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="personalInfo.incomeSource.file" :folderPath="personalInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" v-if="personalInfo.incomeSource.employmentStatus == 'BUSINESS'" bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.company') + $t('page.optional')" :span="3">
				<a-input v-model="personalInfo.incomeSource.company" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.remark') + $t('page.optional')" :span="3">
				<a-input type="textarea" :rows="4" :maxLength="100" v-model="personalInfo.incomeSource.remarks" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docCor') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="personalInfo.incomeSource.file" :folderPath="personalInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docCr2') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="personalInfo.incomeSource.cr2Documents" :folderPath="personalInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docBR') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="personalInfo.incomeSource.boardResolution" :folderPath="personalInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docBTL') + $t('page.optional')" :span="3">
				<UploadPreviewFile
					:uploadFilePath.sync="personalInfo.incomeSource.businessTradeLicence"
					:folderPath="personalInfo.loanId"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" v-if="personalInfo.incomeSource.employmentStatus == 'UNEMPLOYMENT'" bordered size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.remark') + $t('page.optional')" :span="3">
				<a-input type="textarea" :rows="4" :maxLength="100" v-model="personalInfo.incomeSource.remarks" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.documentation') + $t('page.optional')">
				<UploadPreviewFile :uploadFilePath.sync="personalInfo.incomeSource.file" :folderPath="personalInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.personalLabel.loanPurpose')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.loanPurpose')">
				<a-input type="textarea" v-model="personalInfo.loanUse" :rows="4" :maxLength="300" />
			</a-descriptions-item>
		</a-descriptions>
		<a-modal v-drag-modal v-model="exampleModal.show" title="Example" class="example">
			<template slot="footer">
				<span></span>
			</template>
			<div class="example-info" v-show="exampleModal.type === 1">
				<p>Customer photos</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 2">
				<p>ID photos</p>
				<p>Take the photos as the following examples</p>
			</div>
			<a-row :gutter="16" class="example-img">
				<viewer v-show="exampleModal.type === 1">
					<img src="@/assets/example-customer-pic.png" />
				</viewer>
				<viewer v-show="exampleModal.type === 2">
					<a-col :span="12">
						<a-card title="ID front" :bordered="false">
							<img src="@/assets/ID-front@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="ID back" :bordered="false">
							<img src="@/assets/ID-back@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Holding ID" :bordered="false">
							<img src="@/assets/Holding-ID@2x.png" />
						</a-card>
					</a-col>
				</viewer>
			</a-row>
		</a-modal>
	</div>
</template>
<script>
import { apiGetPIN } from '@/api/loan'
export default {
	props: ['personalInfo'],
	data() {
		return {
			dateFormatVal: 'YYYY-MM-DD',
			exampleModal: {
				show: false,
				type: 1
			},
			pinFileBool: false
		}
	},
	methods: {
		basicMobile() {
			if (this.personalInfo.basicMobile) {
				this.personalInfo.basicMobile = this.personalInfo.basicMobile.split(' ').join('')
				if (this.personalInfo.basicMobile.indexOf('254') == 0) {
					this.personalInfo.basicMobile = this.personalInfo.basicMobile.slice(3)
				} else if (this.personalInfo.basicMobile.indexOf('+254') == 0) {
					this.personalInfo.basicMobile = this.personalInfo.basicMobile.slice(4)
				}
			}
		},
		pinChecker() {
			if (this.personalInfo.pin.pinNum !== '') {
				this.$store.commit('pageState/setUploadState', true)
				apiGetPIN({ pin: this.personalInfo.pin.pinNum })
					.then((res) => {
						if (res.effectiveTime) {
							res.effectiveTime = res.effectiveTime.split('/').reverse().join('-')
						}
						this.personalInfo.pin = Object.assign(this.personalInfo.pin, res)
						this.pinFileBool = true
					})
					.catch(() => {
						this.pinFileBool = false
					})
					.finally(() => {
						this.$store.commit('pageState/setUploadState', false)
					})
			} else {
				this.$error({ content: 'Please fill in the PIN!' })
			}
		}
	},
	created() {
		this.$nextTick(() => {
			if (!this.$Util.isEmpty(this.personalInfo.pin.id)) {
				this.pinFileBool = true
			}
		})
	}
}
</script>
<style scoped lang="less">
.ant-input,
.ant-select,
.ant-input-number {
	width: 200px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	vertical-align: top;
	margin-top: 10px;
}
textarea.ant-input {
	width: 500px;
}
</style>
