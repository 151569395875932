<template>
	<div>
		<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFile')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.logbook.logbookFile" :folderPath="loanCarInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFileInfo')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
				<a-auto-complete v-model="loanCarInfo.logbook.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
				<a-auto-complete v-model="loanCarInfo.logbook.model" :data-source="vehicleModelData" placeholder="Vehicle Model" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
				<a-input v-model="loanCarInfo.logbook.year" placeholder="Vehicle Years" @change="$emit('onLogbookYearChange')" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
				<a-input v-model="loanCarInfo.logbook.registrationNumber" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
				<a-input v-model="loanCarInfo.logbook.frameNumber" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
				<a-input v-model="loanCarInfo.logbook.engineNo" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')" :span="3">
				<div class="box">
					<div class="list" v-for="(item, idx) in loanCarInfo.logbook.belongs" :key="idx">
						<div class="span_labe">
							PIN:
							<a-input v-model="loanCarInfo.logbook.belongs[idx].pin" />
						</div>
						<div class="span_labe">
							Name:
							<a-input v-model="loanCarInfo.logbook.belongs[idx].name" />
						</div>
						<a-button type="primary" @click="DelBelongs(idx)">{{ $t('page.delete') }}</a-button>
					</div>
					<div class="add-btn">
						<a-button type="primary" @click="AddBelongs" :disabled="loanCarInfo.logbook.belongs.length >= 2">
							{{ $t('page.add') }}
						</a-button>
					</div>
				</div>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
				<a-auto-complete v-model="loanCarInfo.logbook.vehicleUse" :data-source="$enumMap.vehicleUseEnum" placeholder="Vehicle Use" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions
			bordered
			:title="loanType === 7 ? $t('table.applyInfo.vehicleLabel.carPic') + $t('page.optional') : $t('table.applyInfo.vehicleLabel.carPic')"
			size="small"
		>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.carFace')" :span="2">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.pic.appearancePics" :folderPath="loanCarInfo.loanId" :isImgFile="true">
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 1
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.carInside')" :span="2">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.pic.interiorPics" :folderPath="loanCarInfo.loanId" :isImgFile="true">
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 2
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.down')" :span="2">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.pic.chassisPics" :folderPath="loanCarInfo.loanId" :isImgFile="true">
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 3
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engine')" :span="2">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.pic.enginePics" :folderPath="loanCarInfo.loanId" :isImgFile="true">
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 4
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.detail')" :span="2">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.pic.detailPics" :folderPath="loanCarInfo.loanId" :isImgFile="true">
					<template v-slot:btn>
						<span
							class="example-click"
							@click="
								exampleModal.show = true
								exampleModal.type = 5
							"
							>Example<a-icon type="exclamation-circle"
						/></span>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions
			bordered
			:title="
				loanType === 7
					? $t('table.applyInfo.vehicleLabel.estimateBookFile') + $t('page.optional')
					: $t('table.applyInfo.vehicleLabel.estimateBookFile')
			"
			size="small"
		>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimateBookFile')">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.valuation.file" :folderPath="loanCarInfo.loanId" :callbackFun="getValuation">
					<template v-slot:btn>
						<a-button @click="estimateBookFileTxtBool = !estimateBookFileTxtBool" style="margin-left: 10px">
							{{ $t('table.applyInfo.personalLabel.fillManual') }}
						</a-button>
					</template>
				</UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions
			v-show="estimateBookFileTxtBool"
			bordered
			:title="$t('table.applyInfo.vehicleLabel.estimateBookFileTxt') + loanType === 7 ? $t('page.optional') : ''"
			size="small"
		>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
				<a-auto-complete v-model="loanCarInfo.valuation.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
				<a-auto-complete v-model="loanCarInfo.valuation.model" :data-source="vehicleValuationModelData" placeholder="Vehicle Model" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
				<a-input v-model="loanCarInfo.valuation.year" placeholder="Vehicle Years" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
				<a-input v-model="loanCarInfo.valuation.registrationNumber" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
				<a-input v-model="loanCarInfo.valuation.frameNumber" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
				<a-input v-model="loanCarInfo.valuation.engineNo" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimate')">
				<a-input-number v-model="loanCarInfo.valuation.valuation" :precision="0" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.endValue')">
				<a-input-number v-model="loanCarInfo.valuation.saleValuation" :precision="0" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.createTime')">
				<a-date-picker :valueFormat="dateFormat" showTime type="date" v-model="loanCarInfo.valuation.reportTime" />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.insurance')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.allInsurance')">
				<a-radio-group v-model="loanCarInfo.insurance.isAllRisks">
					<a-radio :value="1">
						{{ $t('page.yes') }}
					</a-radio>
					<a-radio :value="0">
						{{ $t('page.no') }}
					</a-radio>
				</a-radio-group>
			</a-descriptions-item>
			<a-descriptions-item
				:label="
					loanCarInfo.insurance.isAllRisks === 0
						? $t('table.applyInfo.vehicleLabel.expTime') + $t('page.optional')
						: $t('table.applyInfo.vehicleLabel.expTime')
				"
				:span="2"
			>
				<a-date-picker :valueFormat="dateFormat" showTime type="date" v-model="loanCarInfo.insurance.expireTime" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.stickersPic')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="loanCarInfo.insurance.insurancePic" :folderPath="loanCarInfo.loanId" :isImgFile="true">
				</UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
			<a-descriptions-item :span="3">
				<a-table
					:columns="supCloums"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="loanCarInfo.supplementFiles"
					:pagination="false"
					bordered
				>
					<span slot="fileName" slot-scope="text, obj">
						<a-input v-model="obj.filename" class="input_table" />
					</span>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">
							{{ !$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : '' }}
						</a>
					</span>
					<span slot="fileRemark" slot-scope="text, obj">
						<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="DelSup(index)">{{ $t('page.delete') }}</a>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<UploadPreviewFile
					:uploadFilePath.sync="loanCarInfo.supplementFiles"
					:folderPath="loanCarInfo.loanId"
					:isPreviewFile="false"
					:callbackFun="setEngine"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<!-- 车辆归属 -->
		<a-modal v-drag-modal v-model="belongsModel.show" :title="belongsModel.title" :destroyOnClose="true" width="800px" @ok="handModalOk">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item label="PIN">
					<a-input v-model="belongsModel.pin" />
				</a-form-model-item>
				<a-form-model-item label="Name">
					<a-input v-model="belongsModel.name" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal v-drag-modal v-model="exampleModal.show" title="Example" class="example">
			<template slot="footer">
				<span></span>
			</template>
			<div class="example-info" v-show="exampleModal.type === 1">
				<p>Vehicle exterior</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 2">
				<p>Vehicle Interior</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 3">
				<p>Vehicle chassis</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 4">
				<p>Vehicle engine</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 5">
				<p>Vehicle details</p>
				<p>Take the photos as the following examples</p>
			</div>
			<a-row :gutter="16" class="example-img">
				<viewer v-show="exampleModal.type === 1">
					<a-row>
						<a-col :span="12">
							<a-card title="Front of vehicle" :bordered="false">
								<img src="@/assets/exterior-1@2x.png" />
							</a-card>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<p class="ant-card-head-title" style="display: block; padding: 16px 8px">Side of vehicle</p>
						<a-col :span="12" style="margin-bottom: 16px">
							<a-card :bordered="false">
								<img src="@/assets/exterior-2@2x.png" />
							</a-card>
						</a-col>
						<a-col :span="12" style="margin-bottom: 16px">
							<a-card :bordered="false">
								<img src="@/assets/exterior-3@2x.png" />
							</a-card>
						</a-col>
						<a-col :span="12" style="margin-bottom: 16px">
							<a-card :bordered="false">
								<img src="@/assets/exterior-4@2x.png" />
							</a-card>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<a-card title="Back of vehicle" :bordered="false">
								<img src="@/assets/exterior-5@2x.png" />
							</a-card>
						</a-col>
					</a-row>
				</viewer>
				<viewer v-show="exampleModal.type === 2">
					<a-col :span="12">
						<a-card title="Front Interior" :bordered="false">
							<img src="@/assets/Interior-1@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Dashboard" :bordered="false">
							<img src="@/assets/Interior-2@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Front Seat" :bordered="false">
							<img src="@/assets/Interior-3@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Back Seat" :bordered="false">
							<img src="@/assets/Interior-4@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Trunk" :bordered="false">
							<img src="@/assets/Interior-5@2x.png" />
						</a-card>
					</a-col>
				</viewer>
				<viewer v-show="exampleModal.type === 3">
					<img src="@/assets/details-2@2x.png" />
				</viewer>
				<viewer v-show="exampleModal.type === 4">
					<img src="@/assets/engine@2x.png" />
				</viewer>
				<viewer v-show="exampleModal.type === 5">
					<img src="@/assets/details-1@2x.png" />
				</viewer>
			</a-row>
		</a-modal>
	</div>
</template>
<script>
import { apiGetValuation } from '@/api/loan'
export default {
	props: ['loanCarInfo', 'loanType'],
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			belongsModel: {
				title: '',
				show: false,
				pin: '',
				name: ''
			},
			estimateBookFileTxtBool: false,
			exampleModal: {
				show: false,
				type: 1
			},
			isEngine: false
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		},
		vehicleData() {
			const json = require('@/utils/car.json')
			let newkey = Object.keys(json).sort()
			let vehicleData = {} //创建一个新的对象，用于存放排好序的键值对
			for (var i = 0; i < newkey.length; i++) {
				vehicleData[newkey[i]] = json[newkey[i]]
			}
			return vehicleData
		},
		vehicleMakeData() {
			return Object.keys(this.vehicleData)
		},
		vehicleModelData() {
			let vehicleModelData = []
			if (!this.$Util.isEmpty(this.loanCarInfo.logbook.make) && this.vehicleData[this.loanCarInfo.logbook.make]) {
				vehicleModelData = this.vehicleData[this.loanCarInfo.logbook.make].map((item) => {
					return item.title
				})
				vehicleModelData.push('Other')
			}
			return vehicleModelData
		},
		vehicleValuationModelData() {
			let vehicleModelData = []
			if (!this.$Util.isEmpty(this.loanCarInfo.valuation.make) && this.vehicleData[this.loanCarInfo.valuation.make]) {
				vehicleModelData = this.vehicleData[this.loanCarInfo.valuation.make].map((item) => {
					return item.title
				})
				vehicleModelData.push('Other')
			}
			return vehicleModelData
		},
		YearList() {
			let arr = []
			const year = new Date().getFullYear()
			for (let i = 2001; i < year + 1; i++) {
				arr.push({
					label: i + '',
					value: i + ''
				})
			}
			return arr
		}
	},
	methods: {
		getValuation(type) {
			apiGetValuation({ filePath: type })
				.then((res) => {
					this.loanCarInfo.valuation = Object.assign(this.loanCarInfo.valuation, res)
					this.estimateBookFileTxtBool = true
				})
				.catch(() => {
					this.estimateBookFileTxtBool = false
					this.loanCarInfo.valuation = Object.assign(this.loanCarInfo.valuation, {
						engineNo: '',
						frameNumber: '',
						valuation: '',
						year: '',
						registrationNumber: '',
						model: '',
						make: '',
						saleValuation: '',
						reportTime: ''
					})
				})
		},
		AddBelongs() {
			this.belongsModel.title = this.$t('page.add')
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
			this.belongsModel.show = true
		},
		DelBelongs(idx) {
			this.loanCarInfo.logbook.belongs.splice(idx, 1)
		},
		handModalOk() {
			const pinRege = /^[A-Za-z0-9]{11}$/
			if (!pinRege.test(this.belongsModel.pin)) {
				this.$message.error('pin error')
				return false
			} else if (!this.belongsModel.name) {
				this.$message.error('name error')
				return false
			}
			this.loanCarInfo.logbook.belongs.push({
				pin: this.belongsModel.pin,
				name: this.belongsModel.name
			})
			this.belongsModel.show = false
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
		},
		setEngine(path) {
			if (this.loanCarInfo.supplementFiles.length > 0) {
				this.loanCarInfo.supplementFiles[0].filename = 'Engine Change'
			}
		},
		// 补充文件删除
		DelSup(idx) {
			this.loanCarInfo.supplementFiles.splice(idx, 1)
		}
	},
	created() {
		this.$nextTick(() => {
			if (!this.$Util.isEmpty(this.loanCarInfo.valuation.id)) {
				this.estimateBookFileTxtBool = true
			}
		})
	}
}
</script>
<style scoped lang="less">
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
</style>
